<template>
    <b-overlay
        :show="is_loading"
        blur="50"
        variant='white'
        spinner-variant="primary"
        spinner-lg
        rounded="lg"
    > 
        <div>
                
            <div>
                <p class="text-muted" style="font-size: 13px; line-height: 20px;"></p>
            </div>
            
            <validation-observer ref="formHistory">
                <b-form>
                    
                    <b-form-group class="form-group-with-label">
                        <validation-provider #default="{ errors }" name="user" rules="required" >
                            <span :class="{
                                'text-muted' : errors.length > 0 ? false : true,
                                'font-weight-bolder': errors.length > 0 ? true : false,
                                'text-danger' : errors.length > 0 ? true : false
                            }" style="font-size: 12px;">Добавить промежуточный статус</span>  
                            
                            <v-select
                                :options="varieries"
                                v-model="status"
                                label="label"
                                :clearable="false"
                                placeholder="Выбрать"
                            >
                                <template slot="no-options">
                                    Нет результатов поиcка
                                </template>
                            </v-select>     
                            
                        </validation-provider>
                    </b-form-group> 

                </b-form>
            </validation-observer>

            <b-button @click="submit" variant="primary" block size="lg" style="border-radius: 16px; margin-top: 22px;">

                <div v-if="is_loading" class="d-flex justify-content-center">
                    <span class="mt-auto mb-auto ml-1">Отправка...</span>
                </div>
                <span v-else>
                    Подтвердить
                </span>

            </b-button>

        </div>
    </b-overlay>
</template>

<script>  
    import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, integer } from '@validations' 
    import vSelect from 'vue-select'

    export default {

        data() {
            return {
                required,
                integer,
                is_loading: false,

                status: {},
            }
        },
        props: {
            params: Object,
            varieries: Array
        },
        methods: {
            submit() {

                this.$refs.formHistory.validate().then((success) => {
                    if (success) {
                        this.is_loading = true;

                        this.$request
                            .post("orders/history.add", {
                                order: this.params.order,
                                history: this.status.id
                            })
                            .then(() => {
                                this.is_loading = false;
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                    title: `Изменения успешно сохранены`,
                                    icon: "CheckCircleIcon",
                                    variant: "success",
                                    },
                                });

                                this.$emit('afterUpdateData', 'close');
                            });
                        }
                });

            }
        },
        computed: {
           
        },
        components: {
            vSelect,
            ValidationProvider,
            ValidationObserver
        },
        watch: {

        },
        mounted() {
        
        }

    }

</script>

<style lang="scss">

</style>