<template>
    <b-overlay
        :show="is_loading"
        blur="50"
        variant='white'
        spinner-variant="primary"
        spinner-lg
        rounded="lg"
    > 
        <div>
                
            <div>
                <p class="text-muted" style="font-size: 13px; line-height: 20px;"></p>
            </div>
            
            <validation-observer ref="form">
                <b-form>
                    
                    <b-form-group class="form-group-with-label">
                        <validation-provider #default="{ errors }" name="conclusion" rules="required" >
                            <span :class="{
                                'text-muted' : errors.length > 0 ? false : true,
                                'font-weight-bolder': errors.length > 0 ? true : false,
                                'text-danger' : errors.length > 0 ? true : false
                            }" style="font-size: 12px;">Заключение</span>  
                            
                             <quill-editor
                                id="content"
                                v-model="form.conclusion"
                                class="form-control font-weight-normal p-0"
                                style="height: auto;"
                                :options="snowOption"
                                />
                        </validation-provider>
                    </b-form-group> 

                    <b-form-group>
                        <p class="text-muted" style="font-size: 13px; line-height: 20px;">Материалы необходимые для ремонта</p>

                        <b-table-simple class="table-striped">
                            <b-thead>
                                <b-tr>
                                    <b-td class="text-black font-weight-bolder">Кол-во</b-td>
                                    <b-td class="text-black font-weight-bolder">Название</b-td>
                                    <b-td></b-td>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-tr v-if="form.materials === 0">
                                    <b-td colspan="3" class="text-center">
                                        <span class="text-muted">Нет материалов</span>
                                    </b-td>
                                </b-tr>
                                <b-tr v-for="(item, index) in form.materials" :key="index">
                                    <template v-if="item.is_edit && editable[item.id]">
                                        <b-td>{{ item.id }}</b-td>
                                        <b-td colspan="2">
                                            <b-form-input v-model="editable[item.id].count" placeholder="Кол-во (шт)"></b-form-input>
                                            <b-form-input v-model="editable[item.id].material" placeholder="Название материала"></b-form-input>
                                        </b-td>
                                        <b-td class="text-right">
                                            <b-link @click="update( item, index)">
                                                <feather-icon icon="CheckIcon" class="text-success" size="15" />
                                            </b-link>
                                            <b-link @click="setEditMode( item, false )" class="ml-1">
                                                <feather-icon icon="XIcon" class="text-danger" size="15" />
                                            </b-link>
                                        </b-td>
                                    </template>
                                    <template v-else>
                                        <b-td class="text-black">{{ item.count }}</b-td>
                                        <b-td class="text-black">{{ item.material }}</b-td>
                                        <b-td class="text-center">
                                            <b-link @click='remove( item, index )' v-b-tooltip.hover title="Удалить" class="ml-1" >
                                                <feather-icon icon="TrashIcon" class="text-danger" size="15" />
                                            </b-link>
                                        </b-td>
                                    </template>
                                </b-tr>
                                <b-tr v-if="isOnModuleAddState">
                                    <b-td colspan="2">
                                        <div class="d-flex">
                                            <b-form-group class="form-group-with-label" style="width: 120px">
                                                <validation-provider #default="{ errors }" rules="required|integer">
                                                    <span :class="{
                                                        'text-muted' : errors.length > 0 ? false : true,
                                                        'font-weight-bolder': errors.length > 0 ? true : false,
                                                        'text-danger' : errors.length > 0 ? true : false
                                                    }" style="font-size: 12px;">Кол-во (шт)</span>                             
                                                    <b-form-input
                                                        id="count"
                                                        v-model="count"
                                                    />
                                                </validation-provider>
                                            </b-form-group>
                                            <b-form-group class="form-group-with-label" style="flex: 1; margin-left: 15px">
                                                <validation-provider #default="{ errors }" rules="required">
                                                    <span :class="{
                                                        'text-muted' : errors.length > 0 ? false : true,
                                                        'font-weight-bolder': errors.length > 0 ? true : false,
                                                        'text-danger' : errors.length > 0 ? true : false
                                                    }" style="font-size: 12px;">Название материала</span>      
                                                    <b-form-input
                                                        id="material"
                                                        v-model="material"
                                                    />
                                                </validation-provider>
                                            </b-form-group>
                                        </div>
                                    </b-td>
                                    <b-td>
                                        <b-link v-b-tooltip.hover title="Добавить" class="mr-1" @click="add">
                                            <feather-icon icon="CheckIcon" class="text-success" size="22" />
                                        </b-link>
                                        <b-link @click="isOnModuleAddState = false" v-b-tooltip.hover title="Отменить" >
                                            <feather-icon icon="XIcon" class="text-danger" size="22" />
                                        </b-link>
                                    </b-td>
                                </b-tr>
                                <b-tr v-if="!isOnModuleAddState">
                                    <b-td colspan="6">
                                        <b-link @click="isOnModuleAddState = true">
                                            Добавить материал
                                        </b-link>
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-form-group>

                    <b-form-group class="form-group-with-label">
                        <validation-provider #default="{ errors }" rules="required|integer">
                            <span :class="{
                                'text-muted' : errors.length > 0 ? false : true,
                                'font-weight-bolder': errors.length > 0 ? true : false,
                                'text-danger' : errors.length > 0 ? true : false
                            }" style="font-size: 12px;">Сумма ($)</span>                             
                            <b-form-input
                                id="summ"
                                v-model="form.summ"
                            />
                        </validation-provider>
                    </b-form-group>
                </b-form>
            </validation-observer>

            <b-button @click="submit" variant="primary" block size="lg" style="border-radius: 16px; margin-top: 22px;">

                <div v-if="is_loading" class="d-flex justify-content-center">
                    <span class="mt-auto mb-auto ml-1">Отправка...</span>
                </div>
                <span v-else>
                    Подтвердить
                </span>

            </b-button>

        </div>
    </b-overlay>
</template>

<script>  
    import "quill/dist/quill.bubble.css";

    import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { quillEditor } from "vue-quill-editor";
    import { required, integer } from '@validations' 
    import User from "@/modules/user/"

    export default {
        props: {
            params: {
                type: Object,
                default: {}
            }
        },
        data() {
            return {
                required,
                integer,
                User,
                is_loading: false,

                form: {
                    materials: [],
                    conclusion: null,
                    summ: null,
                },

                snowOption: {
                    theme: "bubble",
                    placeholder: "",
                    modules: {
                    toolbar: [
                        ["bold", "italic", "underline", "strike"],
                        [{ color: [] }, { background: [] }],
                        [{ font: [] }],
                        [{ align: [] }],
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        ["align", "list", "blockquote", "code-block"],
                        ["link", "image"],
                    ],
                    },
                },

                editable: {},
                isOnModuleAddState: false,
                count: "",
                material: "",
                items: [],
            }
        },
        methods: {
            remove( item, index ) {
               this.form.materials.splice( index, 1 );
            },
            
            add( args ) {

                this.form.materials.push({
                    count: this.count,
                    material: this.material
                })

                this.isOnModuleAddState = false;
                this.material = '';
                this.count = '';

            },

            setEditMode( item, val ) {
                              
               if( val ) {
                    this.$set( this.editable, {
                       count: item.count,
                       material: item.material
                   });
               }
               
               this.$set( item, "is_edit", val ); 
            },

            submit() {

                this.$refs.form.validate().then((success) => {
                    
                    if (success && this.params.hasOwnProperty('order') && this.params.hasOwnProperty('user')) {
                        this.is_loading = true;

                        this.form.order = this.params.order;
                        this.form.user = this.params.user;

                        this.$request
                            .post("orders/conclusion.add", this.form )
                            .then(() => {
                                this.is_loading = false;
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                    title: `Заключение успешно создано`,
                                    icon: "CheckCircleIcon",
                                    variant: "success",
                                    },
                                });

                                this.$emit('afterUpdateData', 'close');

                            });
                    }
                });

            }
        },
        computed: {
           
        },
        components: {
            ValidationProvider,
            ValidationObserver,
            quillEditor
        },
        watch: {

        },
        mounted() {
        
        }

    }

</script>

<style lang="scss">
    #conclusion-modal {
        .ql-tooltip {
            z-index: 10;
        }
    }
</style>