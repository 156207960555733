<template>
   <div>

        <p style="font-weight: 600; font-size: 20px; margin-bottom: 16px;">Заявки на диагностику</p>
        <b-card no-body>
            <b-card-header>

                    <b-col cols="12" class="d-flex justify-content-between">
                        
                        <b-button-group>
                            <template v-for="(status, index) in statuses">
                                    <b-button  @click="setStatus(status.value)" :variant="filter.status == status.value ? 'active' : 'inactive'" size="sm" style="height: 36px;">
                                        <div class="d-flex flex-row" style="align-items: center;">
                                            {{ $t(status.display) }}
                                            <div class="order-counter">
                                                <span v-if="status.value">
                                                    {{counts[status.value]}}
                                                </span>
                                                <span v-else>
                                                    {{counts.total}}
                                                </span>
                                            </div>
                                        </div>  
                                    </b-button>
                                <div v-if="index < statuses.length - 1" class="delimiter"></div>
                            </template>
                        </b-button-group> 

                    </b-col>
                
            </b-card-header>
            
            <b-skeleton-table v-if='is_loading' animation="fade" :columns='7' :rows='5'>
            
            </b-skeleton-table>

            <b-card-body v-else-if='orders.length == 0' class='d-flex flex-column pb-5'>
                <div class="d-flex flex-column justify-content-center">
                    <div class="nodata-icon ml-auto mr-auto"></div>
                    <p class="text-muted mt-1 ml-auto mr-auto text-center">
                        {{$t('tables.no-data')}}
                    </p>
                </div>
            </b-card-body>

            <b-table v-else :items="orders" :fields="fields" responsive="sm" class="t-order">
  
                <template #cell(id)="row">
                    <feather-icon @click="row.toggleDetails" class="text-muted" icon="ChevronRightIcon" size="16" :style="row.detailsShowing ? 'transform: rotate(-90deg) scaleX(-1);' : ''"/>
                    {{row.item.id}}
                </template>

                <template #cell(creator)="row">
                    <div class="font-weight-normal text-nowrap">
                        <div class="d-flex">
                            <b-avatar
                                :variant="Users.utils.resolveAvatarColor( row.item.creator.id )"
                                :text="Users.utils.capitalize(`${row.item.creator.firstName}`, 1 )"
                            />
                            <div class="d-flex flex-column" style="margin-left: 12px;">
                                <span class="font-weight-bolder text-primary text-capitalize m-0" @click="$router.push({ name: 'admin-users-view', params: { id: row.item.creator.id }})">
                                    {{ row.item.creator.firstName }} {{ row.item.creator.lastName }}
                                </span>
                                <span class="text-muted" style="font-size: 11px;">#{{ row.item.creator.id }}</span>
                            </div>
                        </div>
                    </div>
                </template>

                <template #cell(state)="row">
                    <b-badge v-if="row.item.status == 1" variant="outline-primary">На диагностике</b-badge>
                    <b-badge v-else-if="row.item.status == 2" variant="danger">Неисправен</b-badge>
                    <b-badge v-else-if="row.item.status == 3" variant="outline-warning">В работе</b-badge>
                    <b-badge v-else-if="row.item.status == 4" variant="success">Завершено</b-badge>
                </template>

                <template #cell(payment_state)="row">
                    <div class="d-flex" style="align-items: center;">
                        <b-badge v-if="!row.item.payment && row.item.status !== 4" variant="outline-gray">Ожидает заключения</b-badge>
                        <b-badge v-else-if="row.item.payment && row.item.payment.done == 1" variant="outline-gray">Оплачено</b-badge>
                        <b-badge v-else-if="row.item.status == 4" variant="outline-gray">Заключение готово</b-badge>
                        <b-badge v-else variant="outline-gray">Ожидает оплаты</b-badge>

                        <div v-if="row.item.status !== 4" class="ml-1">
                            <b-dropdown
                                size="sm"
                                right
                                no-caret   
                                variant="transparent"
                                class="group-picker"
                            >
                                <template #button-content>
                                    <feather-icon icon="MoreHorizontalIcon" class="text-muted" size="22" />
                                </template>
                                <b-dropdown-item @click="showModal('history', {order: row.item.id, user: row.item.creator.id})" >
                                    Изменить промежуточный статус 
                                </b-dropdown-item>
                                <b-dropdown-item v-if="!row.item.payment" @click="showModal('conclusion', {order: row.item.id, user: row.item.creator.id})">
                                    Добавить заключение
                                </b-dropdown-item>
                                    <b-dropdown-divider></b-dropdown-divider>
                                <b-dropdown-item @click="closeOrder('complete', row.item)">
                                    Закрыть заявку
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </div>
                </template>

                <template #cell(date)="row">
                    {{row.item.date}} <span class="text-muted">{{row.item.time}}</span>
                </template>

                <template #cell(received)="row">
                    <span v-if="row.item.payment && row.item.payment.received > 0">{{(row.item.payment.summ).formatMoney(0, '','')}} $</span>
                    <span v-else>0 $</span>
                </template>

                <template #cell(miner)="row">
                    <span>{{row.item.miner.machine.name}}</span> <br>
                    <span v-if="row.item.miner.serial_number" class="text-muted" style="font-size: 11px">
                        guuid: {{row.item.miner.guuid}} <br>
                        №: {{row.item.miner.serial_number}}
                    </span>
                    <span v-else class="text-muted" style="font-size: 11px">
                        guuid: {{row.item.miner.guuid}}
                    </span>
                </template>

                <template #row-details="row">
                    <b-table-simple class="font-small-3 text-black" responsive>
                        <b-thead>
                            <b-tr>
                                <b-th style="padding-left: 20px; width: 25%">Заключение</b-th>
                                <b-th style="width: 25%">Материалы</b-th>
                                <b-th style="width: 25%">История статусов</b-th>
                                <b-th style="width: 25%">Сумма к оплате</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr>
                                <b-td v-if="row.item.conclusion" class="text-black" style="padding-left: 20px; vertical-align: top">
                                    <span v-html="row.item.conclusion"/>
                                </b-td>
                                <b-td v-else class="text-black" style="padding-left: 20px">нет данных</b-td>

                                <b-td v-if="row.item.materials" class="text-black" style="vertical-align: top">
                                    <ul class="list-unstyled font-weight-light">
                                        <li v-for="(item, index) in JSON.parse(row.item.materials)" :key="index" style="margin: 4px 0;">
                                            <span>{{item.count}} поз. {{item.material}}</span>
                                        </li>
                                    </ul>
                                </b-td>
                                <b-td v-else class="text-black">нет данных</b-td>

                                <b-td class="text-black" style="vertical-align: top">
                                    <ul class="list-unstyled font-weight-light">
                                        <li v-for="(history, index) in row.item.history" :key="index" style="margin: 4px 0;">
                                            <span :class="index == 0 ? '' : 'text-muted'">{{history.date}} {{history.varieties.label}}</span>
                                        </li>
                                    </ul>
                                </b-td>
                                <b-td v-if="row.item.payment" class="text-black" style="vertical-align: top">
                                    <span class="font-weight-bolder" style="font-size: 22px">{{row.item.payment.summ}} $</span>
                                </b-td>
                                <b-td v-else class="text-black">
                                    -
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </template>
            </b-table>
        </b-card>

        <b-modal id="conclusion-modal" size="lg" v-model="modalsVisibility.conclusion" title="Выставление счета" centered hide-footer no-close-on-backdrop>
            <conclusion-modal-content :params="params" @afterUpdateData="get" @close="closeModal"/>
        </b-modal>

        <b-modal id="history-modal" v-model="modalsVisibility.history" title="Изменение промежуточного статуса" centered hide-footer no-close-on-backdrop>
            <history-modal-content :params="params" :varieries="varieries" @afterUpdateData="get" @close="closeModal" />
        </b-modal>

        <b-modal id="complete-modal" v-model="modalsVisibility.complete" title="Закрытие заявки" centered hide-footer no-close-on-backdrop>
            <complete-modal-content :params="params" @afterUpdateData="get" @close="closeModal" />
        </b-modal>

    </div>
</template>

<script>

import Users from "@/modules/admin/users/"

import ConclusionModalContent from "./modals/conclusion"
import HistoryModalContent from "./modals/history"
import CompleteModalContent from "./modals/complete"

export default {
    data() {
        return {
            Users,
            is_loading: false,

            current_page: 0,
            has_more_pages: false,
            last_page: 0,
            total_count: 0,

            orders: [],
            varieries: [],
            params: {},

            filter: {
                status: null
            },

            statuses: [
                { value: null, display: "orders.tabs.all" },
                { value: "diagnostics", display: "orders.tabs.diagnostics" },
                { value: "faulty", display: "orders.tabs.faulty" },
                { value: "work", display: "orders.tabs.work" },
                { value: "complete", display: "orders.tabs.completed" }
            ],

            counts: {
                complete: 0,
                diagnostics: 0,
                faulty: 0,
                work: 0,
                total: 0
            },

            fields: [{key:'id', label: '№'}, {key:'creator', label: "Пользователь"}, {key:'date', label: 'Дата заявки'}, {key:'miner', label: 'Устройства'}, {key:'received', label: 'Оплачено'}, {key:'state', label: 'Статус'}, {key: 'payment_state', label: ''}],
            fields_details: [{key: "isActive", label: 'is Active'}, {key: "age" , label: 'Возраст'}],

            modalsVisibility: {
                conclusion: false,
                history: false,
                complete: false
            }
        }
    },
    components: {
        ConclusionModalContent,
        HistoryModalContent,
        CompleteModalContent
    },
    methods: {
        closeModal( action ) {
                    
            Object.keys(this.modalsVisibility).forEach(key => {
                this.modalsVisibility[key] = false;
            });
                
        },
    
        showModal( action, params = {} ) {

            this.params = params;
            
            Object.keys(this.modalsVisibility).forEach(key => {
                this.modalsVisibility[key] = false;
            });
            
            if( this.modalsVisibility.hasOwnProperty(action) ) {
                this.modalsVisibility[action] = true;
            }

        },

        get(action = null) {
            if (action) {
                if (action === 'close') this.closeModal();
            }

            this.is_loading = true;
            this.$request.get("orders/list", {status: this.filter.status})
            .then( rsp => {
                this.orders = rsp.list;
                this.counts = rsp.counts;
                this.varieries = rsp.history_varieries;
                this.is_loading = false;
            });
        },

        setStatus( value ) {
            this.filter.status = value;

            this.get();
        },

        toggleCollapse(item) {
                
            if( item.collapsed === null || typeof(item.collapsed) === "undefined" ) {
                this.$set( item, 'collapsed', true );
            } else {
                this.$set( item, 'collapsed', !item.collapsed );
            }
                            
        },

        closeOrder( action, params = {} ) {
            if (params.payment) {
                this.$swal({
                    title: `Подтвердите действие`,
                    text: `Вы действительно хотите закрыть заявку и запустить майнер?`,
                    icon: 'success',
                    showCancelButton: true,
                    confirmButtonText: 'Подтвердить',
                    cancelButtonText: 'Отмена',
                    customClass: {
                      confirmButton: 'btn btn-success',
                      cancelButton: 'btn btn-outline-secondary ml-1',
                    },
                    buttonsStyling: false,
                }).then(result => {
                    if (result.value) {
                        this.$request.post("orders/complete", {
                            order: params.id
                        }).then( rsp => {
                            this.$swal({
                                icon: 'success',
                                title: 'Заявка успешно закрыта',
                                customClass: {
                                  confirmButton: 'btn btn-success',
                                },
                            });

                            this.get();
                        });
                    }
                });
            } else {
                this.showModal(action, params)
            }
        },
            

    },
    mounted() {
       this.get();
    }
}
</script>

<style lang="scss">
    .t-order {
        td {
            color: #141C40;
            &:first-child {
            padding-left: 8px;
            }
        }
    }
    .order-counter {
        background: #9CA2B1;
        border-radius: 30px;
        color: #FFFFFF;
        font-size: 9px;
        padding: 2px 4px;
        margin-left: 6px;
    }
    .b-table-details > td {       
        padding: 0px !important;
    }
</style>