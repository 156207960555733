<template>
    <b-overlay
        :show="is_loading"
        blur="50"
        variant='white'
        spinner-variant="primary"
        spinner-lg
        rounded="lg"
    > 
        <div>
                
            <div>
                <p class="text-muted" style="font-size: 13px; line-height: 20px;"></p>
            </div>
            
            <validation-observer ref="formComplete">
                <b-form>
                    
                    <b-form-group class="form-group-with-label">
                        <validation-provider #default="{ errors }" rules="required">
                            <span :class="{
                                'text-muted' : errors.length > 0 ? false : true,
                                'font-weight-bolder': errors.length > 0 ? true : false,
                                'text-danger' : errors.length > 0 ? true : false
                            }" style="font-size: 12px;">Заключение заявки</span>                             
                            <quill-editor
                                id="content"
                                v-model="conclusion"
                                class="form-control font-weight-normal p-0"
                                style="height: auto;"
                                :options="snowOption"
                                />
                        </validation-provider>
                    </b-form-group>

                </b-form>
            </validation-observer>

            <b-button @click="submit" variant="primary" block size="lg" style="border-radius: 16px; margin-top: 22px;">

                <div v-if="is_loading" class="d-flex justify-content-center">
                    <span class="mt-auto mb-auto ml-1">Отправка...</span>
                </div>
                <span v-else>
                    Подтвердить
                </span>

            </b-button>

        </div>
    </b-overlay>
</template>

<script>  
    import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, integer } from '@validations' 
    import { quillEditor } from "vue-quill-editor";
    import "quill/dist/quill.bubble.css";

    export default {

        data() {
            return {
                required,
                integer,
                is_loading: false,

                conclusion: null,

                snowOption: {
                    theme: "bubble",
                    placeholder: "",
                    modules: {
                    toolbar: [
                        ["bold", "italic", "underline", "strike"],
                        [{ color: [] }, { background: [] }],
                        [{ font: [] }],
                        [{ align: [] }],
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        ["align", "list", "blockquote", "code-block"],
                        ["link", "image"],
                    ],
                    },
                },
            }
        },
        props: {
            params: Object,
        },
        methods: {
            submit() {

                this.$refs.formComplete.validate().then((success) => {
                    if (success) {
                        this.is_loading = true;

                        this.$request
                            .post("orders/complete", {
                                order: this.params.id,
                                conclusion: this.conclusion
                            })
                            .then(() => {
                                this.is_loading = false;
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                    title: `Изменения успешно сохранены`,
                                    icon: "CheckCircleIcon",
                                    variant: "success",
                                    },
                                });

                                this.$emit('afterUpdateData', 'close');
                            });
                        }
                });

            }
        },
        computed: {
           
        },
        components: {
            quillEditor,
            ValidationProvider,
            ValidationObserver
        },
        watch: {

        },
        mounted() {
        
        }

    }

</script>

<style lang="scss">

</style>